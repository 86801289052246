import React, { useContext, useState, Fragment } from 'react';
import { Col } from 'reactstrap';
import { Image } from 'react-bootstrap';
import { ImageContext } from '../../context/ImageContext';
import image2 from '../../image2.jpg';
import image3 from '../../image3.jpg';
import image4 from '../../image4.jpg';

const ImagePicker = () => {
  const [state, setState] = useContext(ImageContext);
  const [picked, setPicked] = useState(0);
  const [border, setBorder] = useState([
    {
      selected: false,
    },
    {
      selected: false,
    },
    {
      selected: false,
    },
  ]);

  const onClickHandler = (index) => (e) => {
    e.preventDefault();
    setPicked(index);
    var ndx = 0;
    const newBorders = border.map((item) => {
      if (ndx === index) {
        item.selected = true;
      } else {
        item.selected = false;
      }
      ndx = ndx + 1;
      return item;
    });
    setBorder(newBorders);
    setState((state) => ({ ...state, imagePicked: index + 1 }));
    console.log('got image index=' + index + 1);
  };

  return (
    <Fragment>
      <Col sm='4'>
        <Image
          className={border[0]['selected'] ? 'withBorder' : 'noBorder'}
          src={image2}
          height={245}
          width={400}
          onClick={onClickHandler(0)}
        />
      </Col>
      <Col sm='4'>
        <Image
          className={border[1].selected ? 'withBorder' : 'noBorder'}
          src={image3}
          height={245}
          width={400}
          onClick={onClickHandler(1)}
        />
      </Col>
      <Col sm='4'>
        <Image
          className={border[2].selected ? 'withBorder' : 'noBorder'}
          src={image4}
          height={245}
          width={400}
          onClick={onClickHandler(2)}
        />
      </Col>
    </Fragment>
  );
};

export default ImagePicker;
