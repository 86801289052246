import React from 'react';
import './App.css';
import Action from './components/layouts/Action';
import { ImageProvider } from './context/ImageContext';

const App = () => {
  return (
    <div className='App'>
      <header className='App-header'>
        <ImageProvider>
          <form>
            <Action />
          </form>
        </ImageProvider>
      </header>
    </div>
  );
};

export default App;
