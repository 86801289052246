import Axios from 'axios';
import React, { useContext, useState, Fragment } from 'react';
import { Col } from 'reactstrap';
import { ImageContext } from '../../context/ImageContext';
import pkgJson from '../../../package.json';

const Topic = () => {
  const [subject, setSubject] = useState('');
  const [state, setState] = useContext(ImageContext);

  const pkgBaseUrl = pkgJson.proxy;
  Axios.defaults.baseURL = pkgBaseUrl;

  const handleChange = (e) => {
    setSubject(e.target.value);
    console.log('topic is ' + subject);
  };

  const handleBlur = (e) => {
    console.log('blur topic is ' + e.target.value);
    setState((state) => ({ ...state, topic: subject }));
  };

  return (
    <Fragment>
      <Col md='12'>
        <input
          placeholder='Summarize Link'
          value={subject}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Col>
    </Fragment>
  );
};

export default Topic;
