import Axios from 'axios';
import React, { useContext, useState, Fragment } from 'react';
import { Button, Col } from 'reactstrap';
import { ImageContext } from '../../context/ImageContext';
import pkgJson from '../../../package.json';
import { saveAs } from 'file-saver';

const Link = () => {
  const [area, setArea] = useState('');
  const [state, setState] = useContext(ImageContext);

  const pkgBaseUrl = pkgJson.proxy;
  Axios.defaults.baseURL = pkgBaseUrl;

  const handleChange = (e) => {
    e.preventDefault();
    setArea(e.target.value);
    setState((state) => ({ ...state, contentLines: area }));
  };

  const encode = (url) => {
    const newUrl = url.replace(/@/, '***');
    return encodeURIComponent(newUrl);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const lines = area
      .split(/\n/)
      .map((l) => encode(l))
      .join('@');
    const subject = state.topic;
    console.log(
      'lines are ' +
        lines +
        ' imagePicked ' +
        state.imagePicked +
        ' topic ' +
        subject
    );
    sendRequest(lines, subject, state.imagePicked);
  };

  const sendRequest = (content, subject, index) => {
    Axios.get(`${index}/${subject}/${content}`, { responseType: 'blob' })
      .then((response) => {
        saveAs(response.data, `image${index}.jpg`);
      })
      .catch((err) => console.log(err));
  };

  return (
    <Fragment>
      <Col md='12'>
        <textarea
          placeholder='Link here'
          value={area}
          rows='4'
          cols='54'
          onChange={handleChange}
        />
        <Button type='submit' onClick={handleSubmit}>
          Generate
        </Button>
      </Col>
    </Fragment>
  );
};

export default Link;
