import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import ImagePicker from './ImagePicker';
import Link from './Link';
import Topic from './Topic';

const Action = () => {
  return (
    <Container>
      <Row>
        <Col>Select image.</Col>
      </Row>
      <Row>
        <ImagePicker />
      </Row>
      <Row>
        <Col>Create a subject</Col>
      </Row>
      <Row>
        <Topic />
      </Row>
      <Row>
        <Col>Add your link.</Col>
      </Row>
      <Row>
        <Link />
      </Row>
    </Container>
  );
};

export default Action;
